import React from 'react'
import axios from 'axios'
import moment from 'moment'
import cryptoRandomString from 'crypto-random-string'
import numeral from 'numeral'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faCreditCard, faDollarSign, faIdCard, faUser } from '@fortawesome/free-solid-svg-icons'

export const buildStorageKey = (subFolder, file, filename = null) => {
  const cb = cryptoRandomString({ length: 8 })
  let fn = file.name.replace(/ /g, '_').toLowerCase()
  const filenameParts = fn.split('.')
  if (filename) {
    fn = `${filename}-${cb}.${filenameParts[1]}`.toLowerCase()
  } else {
    fn = `${filenameParts[0]}-${cb}.${filenameParts[1]}`.toLowerCase()
  }

  if (subFolder) {
    return `${subFolder}/${fn}`
  }
  return fn
}

export const getSessionInfo = () => {
  let sessionInfoJson = { userAgent: null, ipAddress: null }
  try {
    let sessionInfo = window.localStorage.getItem('sessionInfo')
    if (sessionInfo) {
      sessionInfoJson = JSON.parse(sessionInfo)
    }
  } catch(e) {
    console.error(e)
  }

  if (!sessionInfoJson.userAgent && window?.navigator?.userAgent) {
    sessionInfoJson.userAgent = window.navigator.userAgent
  }
  return sessionInfoJson
}

export const months = [
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' }
]

export const uploads = [
  {
    id: 1,
    slug: 'drivers_license',
    name: 'Drivers License',
    description: 'When selected, the user will need to upload a photo of the drivers license.',
    twoSided: true,
    type: 'LICENSE',
    icon: <FontAwesomeIcon icon={faIdCard} />,
    lightNote: <>Please click either 'Front' or 'Back' to capture the required images using your device.</>
  },
  {
    id: 2,
    slug: 'credit_card',
    name: 'Credit Card',
    description: 'When selected, the user will need to upload a photo of the credit card.',
    twoSided: true,
    type: 'CARD',
    icon: <FontAwesomeIcon icon={faCreditCard} />
  },
  {
    id: 3,
    slug: 'proof_of_income',
    name: 'Proof of Income',
    description: 'When selected, the user will need to upload a photo of some form of proof of income.',
    twoSided: false,
    type: 'PROOF_OF_INCOME',
    icon: <FontAwesomeIcon icon={faDollarSign} />
  },
  {
    id: 6,
    slug: 'proof_of_income_2',
    name: 'Second Proof of Income',
    description: 'When selected, the user will need to upload a photo of some form of secondary proof of income.',
    twoSided: false,
    type: 'PROOF_OF_INCOME',
    icon: <FontAwesomeIcon icon={faDollarSign} />
  },
  {
    id: 4,
    slug: 'proof_of_address',
    name: 'Proof of Address',
    description: 'When selected, the user will need to upload a photo of some form of proof of address.',
    twoSided: false,
    type: 'PROOF_OF_ADDRESS',
    icon: <FontAwesomeIcon icon={faAddressCard} />
  },
  {
    id: 5,
    slug: 'selfie',
    name: 'Selfie',
    description: 'When selected, the user will need to upload a photo of them selves.',
    twoSided: false,
    type: 'SELFIE',
    icon: <FontAwesomeIcon icon={faUser} />,
    note: <><strong>ALERT</strong>: You must be holding your <strong className="red">Drivers License</strong> in your Selfie.</>
  },
  {
    id: 8,
    slug: 'selfie_left',
    name: 'Selfie Left',
    frontDescription: <>Take a photo of the <strong>LEFT</strong> side of your face from the shoulders up.</>,
    description: 'When selected, the user will need to upload a photo of them selves from the left side.',
    twoSided: false,
    type: 'SELFIE_LEFT',
    icon: <FontAwesomeIcon icon={faUser} />
  },
  {
    id: 9,
    slug: 'selfie_right',
    name: 'Selfie Right',
    frontDescription: <>Take a photo of the <strong>RIGHT</strong> side of your face from the shoulders up.</>,
    description: 'When selected, the user will need to upload a photo of them selves from the left side.',
    twoSided: false,
    type: 'SELFIE_RIGHT',
    icon: <FontAwesomeIcon icon={faUser} />
  },
  {
    id: 7,
    slug: 'defenant_pic',
    name: 'Defendant Picture',
    description: 'When selected, the user will need to upload a photo of the Defendant.',
    twoSided: false,
    type: 'DEFENDANT_PIC',
    icon: <FontAwesomeIcon icon={faUser} />
  }
]

export const buildFormData = (file, data, storageKey = null) => {
  if (!storageKey) {
    return
  }

  const params = {
    'key': storageKey,
    'policy': data['policy'],
    'Content-Type': file.type,
    'x-amz-credential': data['xAmzCredential'],
    'x-amz-algorithm': data['xAmzAlgorithm'],
    'x-amz-date': data['xAmzDate'],
    'x-amz-signature': data['xAmzSignature']
  }

  if (data.metadata) {
    Object.keys(data.metadata).forEach(k => params[k.toLowerCase()] = data.metadata[k])
  } else if (data['xAmzMetaUuid']) {
    params['x-amz-meta-uuid'] = data['xAmzMetaUuid']
  }

  params.file = file
  params.acl = data['acl']

  const formData = new FormData()
  for (let key in params) {
    formData.append(key, params[key])
  }

  return { params, formData }
}

export const doUpload = async (data, formData, options, params) => {
  const s3response = await axios.post(data.s3Url, formData, options)
  if (s3response) {
    params.url = `${data.s3Url}/${params.key}`
    return { params, s3response, signedUrlData: data }
  } else {
    throw new Error('Could not upload.')
  }
}

export const ucwords = (str) => {
  let words = str ? str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, s => s.toUpperCase()) : ''
  return words.replace('Deann', 'DeAnn')
}

/* eslint-disable */
export const validPhoneNumber = str => {
  if (!str) return false
  return str.match(/^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/)
}
/* eslint-enable */

export const isNumber = n => { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

/* eslint-disable */
export const validEmail = str => {
  if (!str) return false
  return str.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
}
/* eslint-enable */

export const defaultMetatags = props => {
  const title = props.title ? `${props.title} | Simply Bail` : 'Simply Bail'
  const image = props.image ? props.image : "https://simplybail.com/images/home_hero_1.jpg"
  if (props.title.length > 65 && process.env.REACT_APP_BUILD !== 'production') console.error('[SEO] Meta Title is too long.')
  if (props.description.length > 170 && process.env.REACT_APP_BUILD !== 'production') console.error('[SEO] Meta Description is too long.')
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={props.description} />
      <link rel="canonical" href={props.url} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={props.url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:image" content={image} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={props.url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={props.description} />
      <meta property="twitter:image" content={image} />
    </Helmet>
  )
}

export const isUploadSupported = () => {
  if (navigator.userAgent.match(/(Android (1.0|1.1|1.5|1.6|2.0|2.1))|(Windows Phone (OS 7|8.0))|(XBLWP)|(ZuneWP)|(w(eb)?OSBrowser)|(webOS)|(Kindle\/(1.0|2.0|2.5|3.0))/)) {
      return false
  }
  var elem = document.createElement('input')
  elem.type = 'file'
  return !elem.disabled
}

export const fetchLocation = zipCode => {
  return new Promise((resolve, reject) => {
    const client = new XMLHttpRequest()
    client.open("GET", `${window.location.protocol}//api.zippopotam.us/us/${zipCode}`, true)
    client.onreadystatechange = () => {
      if(client.readyState === 4) {
        const json = JSON.parse(client.responseText)
        if (json.places && json.places.length > 0) {
          return resolve({ city: json.places[0]['place name'], state: json.places[0]['state'], zip: json['post code'] })
        }
        return reject({error: 'Place not found.'})
      }
    }
    client.send()
  })
}

// export const fetchLocation = zipCode => {
//   return new Promise((resolve, reject) => {
//     const client = new XMLHttpRequest()

//     // Build url
//     const url = `https://www.zipcodeapi.com/rest/js-S3mEh83eiDcicxlwCnl0mzsffXI76ClxpD96FjUWaeGQTHrcqvwZDJXbyPj7aUn5/info.json/${zipCode}/radians`
//     client.open("GET", url, true)
//     client.onreadystatechange = () => {
//       if(client.readyState === 4) {
//         const json = JSON.parse(client.responseText)
//         if (json) {
//           return resolve({ city: json.city, state: json.state, zip: json.zip_code })
//         }
//         return reject({error: 'Place not found.'})
//       }
//     }
//     client.send()
//   })
// }

export const binLookup = number => {
  return new Promise((resolve, reject) => {
    const client = new XMLHttpRequest()
    client.open("GET", `https://lookup.binlist.net/${number}`, true)
    client.onreadystatechange = () => {
      if(client.readyState === 4) {
        try {
          const json = JSON.parse(client.responseText)
          if (json) {
            return resolve(json)
          }
          return reject({error: 'Bin not found.'})
        } catch(e) {
          return reject({error: 'Could not Parse BIN Response, see console.'})
        }
      }
    }
    client.send()
  })
}

export const formatMoney = (val, endingZeros = false, isDivided = true) => {
  val = isDivided ? (val / 100) : val
  let amt = numeral(val).format('0,0.00')
  if (val < 100) {
    return amt
  }
  if (endingZeros) return amt
  return amt.replace('.00', '')
}

export const formatPhoneNumber = phoneNumberString => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}

export const formatCreditCard = str => {
  var v = str.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
  var matches = v.match(/\d{4,16}/g)
  var match = matches && matches[0] || '' // eslint-disable-line
  var parts = []
  var i = 0
  var len = match.length
  for (i; i<len; i+=4) {
    parts.push(match.substring(i, i+4))
  }
  if (parts.length) {
    return parts.join(' ')
  }
  return str
}

export const loadCollectJsScript = (apiKey, callback) => {
  const existingScript = document.getElementById('collectJs')
  if (!existingScript) {
    const script = document.createElement('script')
    script.src = 'https://secure.safewebservices.com/token/Collect.js'
    script.id = 'collectJs'
    script.setAttribute('data-tokenization-key', apiKey)
    document.body.appendChild(script)
    script.addEventListener('load', function() {
      if (window.CollectJS && callback) callback() 
    })
  }
  if (existingScript && callback) callback()
}

export const loadPlacesJsScript = (apiKey, callback) => {
  const existingScript = document.getElementById('placesJs')
  if (!existingScript) {
    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`
    script.id = 'placesJs'
    document.body.appendChild(script)
    script.addEventListener('load', function() {
      if (window.CollectJS && callback) callback() 
    })
  }
  if (existingScript && callback) callback()
}

export const getBondsmanType = (bondsman, type) => {
  if (type === 'MERCHANT') {
    return bondsman.hasMerchantAccount
  } else if (type === 'CASH') {
    return bondsman.hasCardOnFile
  }
}

export const getType = data => {
  if (data.defendant.status === 'BAIL_POSTED') {
    return <div className="tag tag--blue">Bail Posted</div>
  } else if (data.financesSecured && data.defendant.status === 'ACCEPTED') {
    return <div className="tag tag--teal">Accepted</div>
  } else if (!data.isConfirmed) {
    if (!data.isQuestionnaireFinished) {
      return <div className="tag tag--grey">Waiting on Questionnaire</div>
    } else {
      return <div className="tag tag--red">New</div>
    }
  } else if (!data.financesUpdated) {
    return <div className="tag tag--red">FINANCES INVALID</div>
  } else {
    if (!data.isQuoteConfirmed) {
      return <div className="tag tag--grey">Confirming Quote</div>
    } else {
      if (!data.isFormsComplete) {
        return <div className="tag tag--grey">Entering Information</div>
      } else {
        if (!data.isApproved) {
          return <div className="tag tag--red">Ready for Review</div>
        } else {
          if (!data.financesEntered) {
            return <div className="tag tag--grey">Waiting on Finances</div>
          } else {
            if (!data.financesSecured) {
              return <div className="tag tag--grey">Securing Finances</div>
            } else {
              return <div className="tag tag--teal">Accepted</div>
            }
          }
        } 
      }
    }
  }
}

export const getTypeEnhanced = (data, justText = false) => {
  let setStatus = (data.searchStatus) ? data.searchStatus : data.status
  let status = setStatus.replace(/_/g, ' ')
  if (['EXONERATED'].indexOf(setStatus) >= 0) {
    if (justText) return 'Exonerated'
    return <div className="tag tag--blue">Exonerated</div>
  }
  if (['BAIL_POSTED','ACCEPTED'].indexOf(setStatus) >= 0) {
    if (justText) return 'Accepted'
    return <div className="tag tag--blue">Complete</div>
  } else if (setStatus === 'AWAITING_APPROVAL') {
    if (justText) return 'Ready for Review'
    return <div className="tag tag--red">Ready for Review</div>
  } else if (setStatus === 'NEW') {
    if (justText) return 'New'
    return <div className="tag tag--red">New</div>
  } else if (['LEAD'].indexOf(setStatus) >= 0) {
    if (justText) return 'Lead'
    return <div className="tag tag--orange">Lead</div>
  }
  if (justText) return status
  return <div className="tag tag--grey">{status}</div>
}

export const getTypeEnhancedDef = (data, justText = false) => {
  let setStatus = (data.searchStatus) ? data.searchStatus : data.status
  let status = setStatus.replace(/_/g, ' ')
  if (['BAIL_POSTED','ACCEPTED'].indexOf(setStatus) >= 0) {
    return <div className="tag tag--blue">CHECK</div>
  } else if (setStatus === 'AWAITING_APPROVAL') {
    return <div className="tag tag--red">Ready for Review</div>
  } else if (setStatus === 'AWAITING_APPROVAL') {
    return <div className="tag tag--red">Ready for Review</div>
  } else if (['LEAD'].indexOf(setStatus) >= 0) {
    return <div className="tag tag--orange">Lead</div>
  }
  if (justText) return status
  return <div className="tag tag--grey">{status}</div>
}

export const getUrlVars = () => {
  var vars = {}
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m,key,value) => vars[key] = value)
  return vars
}

export const getFiltersArray = () => {
  const urlParams = getUrlVars()
  const newFilters = []
  Object.keys(urlParams).forEach(key => {
    if (key === 'status') {
      newFilters.push({ key, type: 'STATUS', value: urlParams[key].split(',') })
    } else if (['from','to'].indexOf(key) < 0) {
      newFilters.push({ key, type: key.toUpperCase(), value: urlParams[key] })
    }
  })
  if (urlParams.from && urlParams.to) {
    newFilters.push({ key: 'date', type: 'DATE', value: { from: urlParams.from, to: urlParams.to } })
  }
  return newFilters
}

export const getCashAmount = total => {
  if (total < 0) {
    return '-$' + numeral(Math.abs(total)).format('0,0.00')
  }
  return '$' + numeral(total).format('0,0.00')
}

export const isValid = (data, arr) => {
  let invalid = []
  arr.forEach(key => {
    if (!data[key] || data[key] === '') invalid.push(key)
  })
  // if (invalid.length) throw new Error(`Invalid entries ${invalid.join(', ')}`)
  return (invalid.length === 0)
}

// formally: getGoogleLatLngBndsFromGeoBoundingBoxFilter
// converts GeoBoundsFilter to GoogleLatLng to set on map
// https://developers.google.com/maps/documentation/javascript/reference/coordinates#LatLngBounds
export const geoToGoogleBounds = (geoBoundingFilterBox, google) => {
  const box = geoBoundingFilterBox.geoBoundingBox
  return new google.maps.LatLngBounds(
    new google.maps.LatLng(box.topLeft.lat, box.topLeft.lon),
    new google.maps.LatLng(box.bottomRight.lat, box.bottomRight.lon)
  )
}


// Formally: getGeoBoundingBoxFilterFromGoogleMapLatLngBnds
// convert GoogleLatLngBounds to SearchKit GeoBoundsFilter for elastic search
// https://developers.google.com/maps/documentation/javascript/reference/coordinates#LatLngBounds
export const googleToGeoBounds = (bounds, identifier) => {
  const ne = bounds.getNorthEast()
  const sw = bounds.getSouthWest()

  const geoBoundingBox = {
    topLeft: {
      lat: ne.lat(),
      lon: sw.lng()
    },
    bottomRight: {
      lat: sw.lat(),
      lon: ne.lng()
    }
  }

  return {
    geoBoundingBox: geoBoundingBox,
    identifier: identifier
  }
}

export const getPlaceDetails = (map, placeId) => {
  const service = new window.google.maps.places.PlacesService(map)
  return new Promise((resolve, reject) => {
    service.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {

        const bounds = new window.window.google.maps.LatLngBounds(
          place.geometry.viewport.getSouthWest(),
          place.geometry.viewport.getNorthEast()
        )

        const location = place.geometry.location
        const position = { lat: location.lat(), lng: location.lng() }
        resolve({ place, location, position, bounds })
      }
    })
  })
}

export const generateFrequencyDates = (date, frequencyAmount, frequencyType, checkInDay, format = false, createdAtDate = null) => {
  if (checkInDay === 'NONE') {
    return []
  }
  const endDate = moment(date).toDate()

  let startDate = null
  if (checkInDay === 'CUSTOM') {
    startDate = createdAtDate ? moment(createdAtDate).toDate() : moment().toDate()
  } else {
    let date = createdAtDate ? moment(createdAtDate) : moment()
    startDate = date.startOf('isoWeek').day(checkInDay.toLowerCase()).toDate()
  }

  let final = []
  let i = 0
  let newDate = null
  while(i < 1500) {
    newDate = moment(startDate).add((i * frequencyAmount), frequencyType.toLowerCase()).toDate()
    if (new Date(newDate).getTime() < new Date(endDate).getTime()) {
      if (format) {
        final.push(moment(newDate).format(format))
      } else {
        final.push(newDate)
      }
    } else {
      break
    }
    i++
    if (i === 1500) {
      console.error("safety triggered")
      break
    }
  }

  if (format) {
    final.push(moment(endDate).format(format))
  } else {
    final.push(endDate)
  }

  return final
}

export const buildQueryStringFromJSON = (params) => {
  var str = Object.keys(params)
    .filter(key => !!params[key])
    .map(key => key + '=' + params[key])
    .join('&')
  return (str) ? '?' + str : ''
}

export const discectError = (e, defaultMessage = null) => {
  if (e.message.substr(0,1) === '{') {
    const errorJson = JSON.parse(e.message)
    return errorJson?.error?.message || defaultMessage || 'General Error'
  }
  return e.message
}

export const getOfficeAddress = office => {
  if (office.placesLocation) {
    const { structured_formatting: { main_text, secondary_text } } = office.placesLocation.value

    return {
      mainText: main_text,
      secondaryText: secondary_text,
      phone: formatPhoneNumber(office.phone),
      email: office.email
    }
  }
  return {
    mainText: `${office.address.address} ${office.address.address2}`,
    secondaryText: `${office.address.city}, ${office.address.state} ${office.address.zip}`,
    phone: formatPhoneNumber(office.phone),
    email: office.email
  }
}

export const getDistanceFromLatLonInKm = (lat1,lon1,lat2,lon2) => {
  const deg2rad = deg => {
    return deg * (Math.PI/180)
  }
  var R = 6371 // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1)  // deg2rad below
  var dLon = deg2rad(lon2-lon1) 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
  var d = R * c // Distance in km
  return d
}

export const parseAddress = data => {
  let shortName = null
  let address = null
  let addressName = null
  let city = null
  let state = null
  let county = null
  if (data) {
    const { label, geoCode: { address_components }, name, value } = data

    if (value?.structured_formatting?.main_text) {
      shortName = value.structured_formatting.main_text
    }

    let number = null
    const numberPart = address_components.find(itm => itm.types.indexOf('street_number') >= 0)
    if (numberPart) {
      number = numberPart.long_name
    }
    let street = null
    const streetPart = address_components.find(itm => itm.types.indexOf('route') >= 0)
    if (streetPart) {
      street = streetPart.short_name
    }

    if (number && street) { 
      address = `${number} ${street}`
    } else if (street) {
      address = `${street}`
    }

    const cityPart = address_components.find(itm => itm.types.indexOf('locality') >= 0)
    if (cityPart) {
      city = cityPart.long_name
    }
    const statePart = address_components.find(itm => itm.types.indexOf('administrative_area_level_1') >= 0)
    if (statePart) {
      state = statePart.long_name
    }
    const countyPart = address_components.find(itm => itm.types.indexOf('administrative_area_level_2') >= 0)
    if (countyPart) {
      county = countyPart.long_name
    }
    addressName = name || label || 'Unknown'
  }

  return { shortName, name: addressName, address, city, state, county }
}

export const isCaseLinked = caseInfo => {
  const { defendant, indemnitors } = caseInfo

  let isLinked = []
  if (defendant?.user?.hasAdditionalCases) {
    isLinked.push(defendant.user.id)
  }

  if (!isLinked && indemnitors) {
    indemnitors.forEach(itm => {
      if (itm?.user?.hasAdditionalCases) isLinked.push(itm.user.id)
    })
  }

  return (isLinked.length > 0) ? isLinked : null
}

export const getName = (obj) => {
  return ucwords(`${obj?.nameFirst} ${obj?.nameLast}`.toLowerCase())
}

export const isValidPowerSubmission = (power) => {
  const requiredFields = ['bond_value','po_number','executed_at','status','type']
  const requiredMetaFields = ['rate', 'buff', 'premiumAmount', 'bondAmount', 'caseNumber', 'defendantName', 'state', 'county', 'city']

  let status = 'INVALID'
  if (isValid(power, requiredFields)) {
    if (isValid(power.meta_data, requiredMetaFields)) {
      status = 'VALID'
    }
  }

  return status
}