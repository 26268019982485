import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import Select from 'react-select'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'
import { useJsApiLoader } from '@react-google-maps/api'
import CreatableSelect from 'react-select/creatable'
import DatePicker from 'react-datepicker'
import Cleave from 'cleave.js/react'
import 'cleave.js/dist/addons/cleave-phone.us' // eslint-disable-line
import TextareaAutosize from 'react-textarea-autosize'
import ReactTooltip from 'react-tooltip'

import SelectState from 'components/InputSelectState'

import 'react-datepicker/dist/react-datepicker-cssmodules.css'

// TODO: fix datepicker styles
// import "react-datepicker/dist/react-datepicker.css"

// ALSO TODO: when refactoring this component for storybook, remove the global import into .storybook/preview.js

const Input = props => {
  const [startDate, setStartDate] = useState(null)
  const [dateRange, setDateRange] = useState([null, null])
  const [time, setTime] = useState(null)
  const [meridiem, setMeridiem] = useState(props.defaultOrdinalSettings ? 'PM' : 'AM')
  const [selectedState, setSelectedState] = useState(null)
  const [address, setAddress] = useState(null)

  const { isNoPlaceholder } = props

  const { isLoaded: isGoogleLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_KEY,
    libraries: ['places','visualization']
  })

  useEffect(() => {
    if (props.type === 'address') {
      if (props.defaultValue) setAddress(props.defaultValue)
    } else if (props.type === 'calendar' && props.defaultValue && props.defaultValue !== '') {
      setStartDate(moment(props.defaultValue).toDate())
    } else if (props.type === 'time' && props.defaultValue && props.defaultValue !== '') {
      const timeParts = props.defaultValue.split(' ')
      if (['AM','PM'].indexOf(timeParts[1]) >= 0) {
        setTime(timeParts[0])
        setMeridiem(timeParts[1])
      }
    } else if (props.type === 'select' && props.defaultValue && props.defaultValue !== '') {
      if (!props.keepClear) setSelectedState(props.defaultValue)
    }
  }, [props.type, props.defaultValue, props.keepClear])

  useEffect(() => {
    if (time && meridiem) {
      props.onChange(props.id, `${time} ${meridiem}`)
    }
  }, [meridiem]) // eslint-disable-line

  const onChange = val => {
    if (['select'].indexOf(props.type) >= 0) {
      let value = val.value
      if (props.isMulti) value = val.map(itm => itm.value)
      if (!props.keepClear) setSelectedState(value)
      if (props.onChange) props.onChange(props.id, value)
    } else if (['radio'].indexOf(props.type) >= 0) {
      if (props.onChange) props.onChange(props.id, val.value)
    } else {
      if (!props.keepClear) setSelectedState(null)
      if (props.onChange) props.onChange(props.id, null)
    }
  }

  const _onSelectDate = date => {
    setStartDate(date)
    if (props.onChange) props.onChange(props.id, moment(date).format('YYYY-MM-DD'))
    if (!props.onChange) console.warn('Let props know I have changed the date: ', date)
  }

  const _setDate = dates => {
    const [start, end] = dates
    setDateRange([start, end])
    if (start && end) {
      let params = {
        from: moment(start).format('YYYY-MM-DD'),
        to: moment(end).format('YYYY-MM-DD')
      }
      if (props.onChange) props.onChange(props.id, params)
      if (!props.onChange) console.warn('Let props know I have changed the date: ', params)
    }
  }

  const _onSetAddress = async a => {
    if (a?.value?.place_id) {
      const results = await geocodeByPlaceId(a.value.place_id)
      if (results.length > 0) {
        const { lat, lng } = results[0].geometry.location
        a.geoCode = results[0]
        a.coords = { lat: lat(), lng: lng() }
        setAddress(a)
        props.onChange(props.id, a)
      }
    }
  }

  const _getSelect = () => {
    const options = Object.entries(props.dropdownOptions).map(([k, v], key) => {
      return { value: k, label: v }
    })
  
    let val = null
    if (selectedState) {
      if (props.isMulti) {
        val = options.filter(opt => selectedState.indexOf(opt.value) >= 0)
      } else {
        val = options.find(opt => opt.value === selectedState)
      }
    } else if (props.defaultValue) {
      if (props.isMulti) {
        val = options.filter(opt => props.defaultValue.indexOf(opt.value) >= 0)
      } else {
        val = options.find(opt => opt.value === props.defaultValue)
      }
    }

    if (props.isCreatable) {
      return <CreatableSelect
        id={props.id}
        value={val}
        isMulti={props.isMulti || false}
        isDisabled={props.isDisabled}
        onChange={val => onChange(val)}
        options={options}
        placeholder={isNoPlaceholder ? null : props.placeholder || "Choose an Option"}
        autoFocus={props.autoFocus || false}
        styles={{
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
      />
    }

    return <Select
      id={props.id}
      value={val}
      isMulti={props.isMulti || false}
      isDisabled={props.isDisabled}
      onChange={val => onChange(val)}
      options={options}
      placeholder={isNoPlaceholder ? null : props.placeholder || "Choose an Option"}
      autoFocus={props.autoFocus || false}
      isClearable={props.isClearable || false}
      styles={{
        menu: provided => ({ ...provided, zIndex: 11999 })
      }}
    />
  }

  const _getRadio = () => {
    return (
      <div className="radio">
        {props.radioOptions.map((opt, optkey) => {
          return (
            <div key={optkey} className="radio__radioItem">
              <input id={`${props.id}__${optkey}`} type="radio" name={`${props.id}`} value={opt.value} defaultChecked={(props.defaultValue === opt.value)} onChange={e => onChange(opt)} />
              <label htmlFor={`${props.id}__${optkey}`}>{opt.text}</label>
            </div>
          )
        })}
      </div>
    )
  }

  const _getInput = () => {
    if (props.type === 'cleave') {
      return (
        <Cleave 
          autoFocus={props.autoFocus || false}
          value={props.defaultValue}
          placeholder={props.placeholder || null}
          options={props.options}
          onChange={e => props.onChange(props.id, e.target.value)}
        /> 
      )
    } else if (props.type === 'radio') {
      return _getRadio()
    } else if (props.type === 'select') {
      const selectedValue = props.dropdownOptions[selectedState]
      return (
        <Fragment>
          {(!selectedState || !props.showDropdownEdit) && _getSelect()}
          {(selectedState && props.showDropdownEdit) && (
            <div className="inputRow__block__selectAnswer">
              <span>{selectedValue}</span>
              <button className="butt butt--teal" onClick={() => setSelectedState(null)}>Edit</button>
            </div>
          )}
        </Fragment>
      )
    } else if (props.type === 'dollar') {
      return <Cleave 
        value={props.defaultValue}
        placeholder={props.placeholder}
        options={{ numeral: true, numeralThousandsGroupStyle: 'thousand' }}
        onChange={e => props.onChange(props.id, e.target.value)} />
    } else if (props.type === 'state') {
      return <SelectState defaultState={props.defaultValue} placeholder={props.placeholder || null} onChange={val => props.onChange(props.id, val.value)} />
    } else if (props.type === 'date') {
      return (
        <Cleave
          id={props.id || null}
          name={props.name || null}
          placeholder="MM/DD/YYYY"
          value={props.defaultValue}
          options={{
            date: true,
            datePattern: ["m", "d", "Y"]
          }}
          onChange={e => props.onChange(props.id, e.target.value)}
        />
      )
    } else if (props.type === 'calendar') {
      if (props.isRange) {
        const [startDate, endDate] = dateRange
        return (
          <DatePicker 
            selectsRange
            dateFormat="M-dd-yyyy"
            onChange={date => _setDate(date)}
            startDate={startDate}
            endDate={endDate}
            maxDate={new Date()}
          />
        )
      }
      return (
        <DatePicker 
          placeholder={'YYYY-MM-DD'}
          dateFormat="MMM. do, yyyy"
          selected={startDate}
          onSelect={date => _onSelectDate(date)}
        />
      )
    } else if (props.type === 'time') {
      return (
        <div className="timeContainer">
          <Cleave 
            value={time}
            placeholder={props.placeholder}
            options={{ time: true, timeFormat: '12', timePattern: ['h', 'm'] }}
            onChange={e => {
              setTime(e.target.value)
              props.onChange(props.id, `${e.target.value} ${meridiem}`)
            }}
          />
          <div className="timeContainer__meridiem">
            <button className={meridiem === 'AM' ? 'active' : ''} onClick={e => { e.preventDefault(); setMeridiem('AM') }}>AM</button>
            <button className={meridiem === 'PM' ? 'active' : ''} onClick={e => { e.preventDefault(); setMeridiem('PM') }}>PM</button>
          </div>
        </div>
      )
    } else if (props.type === 'textarea') {
      return <TextareaAutosize defaultValue={props.defaultValue} value={props.value} autoFocus={props.autoFocus || false} onChange={e => props.onChange(props.id, e.target.value)} />
    } else if (props.type === 'address') {
      if (isGoogleLoaded) {
        return <GooglePlacesAutocomplete
          selectProps={{
            value: address,
            onChange: _onSetAddress
          }}
        />
      }
      return null
    } else if (props.type === 'dob') {
      return <Cleave
        value={props.defaultValue || props.value}
        options={{ blocks: [2,2,4], delimiter: '-', numericOnly: true }}
        onChange={e => props.onChange(props.id, e.target.value)}
      />
    } else if (props.type === 'phone') {
      return <Cleave
        value={props.defaultValue || props.value}
        options={{ blocks: [3,3,4], delimiter: '-', numericOnly: true }}
        onChange={e => props.onChange(props.id, e.target.value)}
      />
    } else if (props.type === 'ssn') {
      return <Cleave
        value={props.defaultValue || props.value}
        options={{ blocks: [3,2,4], delimiter: '-', numericOnly: true }}
        onChange={e => props.onChange(props.id, e.target.value)}
      />
    } else if (props.type === 'cleave') {
      return <Cleave
        value={props.defaultValue}
        options={props.options}
        onChange={e => props.onChange(props.id, e.target.value)}
      />
    }
    return <input id={props.id} type={props.type} disabled={props.isDisabled} defaultValue={props.defaultValue} value={props.value} placeholder={props.placeholder || null} onChange={e => props.onChange(props.id, e.target.value)} autoFocus={props.autoFocus || false} />
  }

  const _getInfo = () => {
    if (props.tooltip) {
      return <i data-for="inputTip" data-tip={props.tooltip} className="fas fa-info-circle"></i>
    }
  }

  return (
    <div className={`inputRow__block ${props.className ? props.className : ""}`}>
      {props.label && 
        <label 
          className={`${props.isRequired ? 'required' : ''}`} 
          htmlFor={props.id}>
            {props.label}
            {_getInfo()}
            {props.isVisibleDisabled && <strong className="infoPacket__fieldDisabled">Disabled</strong>}
        </label>}
      {_getInput()}
      {props.children && props.children}
      {props.helpText && <p className="inputRow__block__helpText">{props.helpText}</p>}
      <ReactTooltip id="inputTip" effect="solid" place="bottom" type="dark" multiline={true} />
    </div>
  )
}

export default Input