import React from "react"
import { Link } from 'react-router-dom'
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { Tooltip } from 'antd'

import styles from "./ButtonPrimary.module.scss"

const ButtonPrimary = ({ type, small, medium, reverseIcon, pulse, icon, color, fullWidth, children, className, disabled, onClick, openUrl, gotoUrl, tooltip }) => {

  let classes = styles.butt
  if (color === 'red') classes = `${classes} ${styles.butt__red}`
  if (color === 'blue') classes = `${classes} ${styles.butt__blue}`
  if (color === 'teal') classes = `${classes} ${styles.butt__teal}`
  if (color === 'white') classes = `${classes} ${styles.butt__white}`
  if (color === 'grey') classes = `${classes} ${styles.butt__grey}`
  if (color === 'orange') classes = `${classes} ${styles.butt__orange}`
  if (color === 'purple') classes = `${classes} ${styles.butt__purple}`
  if (color === 'whiteRed') classes = `${classes} ${styles.butt__whiteRed}`
  if (color === 'green') classes = `${classes} ${styles.butt__green}`
  if (color === 'mutedRed') classes = `${classes} ${styles.butt__mutedRed}`
  if (color === 'clear') classes = `${classes} ${styles.butt__clear}`
  if (fullWidth) classes = `${classes} ${styles.butt__full}`
  if (icon) classes = `${classes} ${styles.butt__icon}`
  if (reverseIcon) classes = `${classes} ${styles.butt__icon__reverse}`
  if (className) classes = `${classes} ${className}`
  if (medium) classes = `${classes} ${styles.butt__medium}`
  if (small) classes = `${classes} ${styles.butt__small}`
  if (pulse) classes = `${classes} ${styles.butt__pulse}`

  if (!children) {
    classes = `${classes} ${styles.butt__iconOnly}`
  }

  if (gotoUrl) {
    return (
      <Tooltip title={tooltip}>
        <Link 
          type={type}
          className={classes}
          to={gotoUrl}>
            {icon && <FontAwesomeIcon icon={icon} />}
            {children}
        </Link>
      </Tooltip>
    )
  } else if (openUrl) {
    return (
      <Tooltip title={tooltip}>
        <Link 
          className={classes}
          to={openUrl}
          target="_blank"
          type={type}
          rel="noreferrer">
            {icon && <FontAwesomeIcon icon={icon} />}
            {children}
        </Link>
      </Tooltip>
    )
  }

  return (
    <Tooltip title={tooltip}>
      <button type={type || 'button'} className={classes} onClick={onClick} disabled={disabled}>
        {icon && <FontAwesomeIcon icon={icon} />}
        {children}
      </button>
    </Tooltip>
  )
}

ButtonPrimary.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

ButtonPrimary.defaultProps = {
  children: undefined,
  className: undefined
}

export default ButtonPrimary